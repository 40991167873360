import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

export default function useLisansSifreBilgis() {
  const lisansSifreBilgisTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: 'Kullanıcı', key: 'kullanici', sortable: true },
    { label: 'Ürün', key: 'urun', sortable: true },
    { label: 'Lisans No', key: 'sLisansNo', sortable: true },
    { label: 'Bitiş Tarih', key: 'dtLisansBitisTarihi', sortable: true },
    { label: 'Durum', key: 'isLisansGecerli', sortable: true },
  ]

  const perPage = ref(10)
  const toplamLisansSifreBilgis = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = computed({
    get() {
      return store.getters['lisanssifrebilgiCustomer/getLisansSifreBilgiFilters'].sortBy
    },
    set(value) {
      store.commit('lisanssifrebilgiCustomer/setLisansSifreBilgiFilters', { key: 'sortBy', value })
    },
  })
  const isSortDirDesc = computed({
    get() {
      return store.getters['lisanssifrebilgiCustomer/getLisansSifreBilgiFilters'].sortDesc
    },
    set(value) {
      store.commit('lisanssifrebilgiCustomer/setLisansSifreBilgiFilters', { key: 'sortDesc', value })
    },
  })

  const dataMeta = computed(() => {
    const localItemsCount = lisansSifreBilgisTable.value ? lisansSifreBilgisTable.value.localItems.length : 0
    return {
      from: currentPage.value === 1 ? 1 : perPage.value * (currentPage.value - 1) + 1,
      to: perPage.value * (currentPage.value) < localItemsCount ? perPage.value * (currentPage.value) : localItemsCount,
      of: toplamLisansSifreBilgis.value,
    }
  })

  const refetchData = () => {
    lisansSifreBilgisTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchLisansSifreBilgis = () => {
    store.dispatch('lisanssifrebilgiCustomer/fetchLisansSifreBilgis')
  }

  const getLisansSifreBilgis = computed(() => {
    const lisanssifrebilgis = store.getters['lisanssifrebilgiCustomer/getLisansSifreBilgis']
    toplamLisansSifreBilgis.value = lisanssifrebilgis.length
    return lisanssifrebilgis
  })

  onMounted(fetchLisansSifreBilgis)

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusIcon = status => {
    if (status === false) return 'XIcon'
    if (status === true) return 'CheckIcon'
    return 'UserIcon'
  }

  const resolveStatusName = status => {
    if (status === false) return 'GEÇERSİZ'
    if (status === true) return 'GEÇERLİ'
    return 'TANIMLI DEĞİL'
  }

  const resolveStatusVariant = status => {
    if (status === false) return 'warning'
    if (status === true) return 'success'
    return 'primary'
  }

  return {
    fetchLisansSifreBilgis,
    getLisansSifreBilgis,
    tableColumns,
    perPage,
    currentPage,
    toplamLisansSifreBilgis,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    lisansSifreBilgisTable,

    resolveStatusIcon,
    resolveStatusName,
    resolveStatusVariant,
  }
}
