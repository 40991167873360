<template>
  <b-card no-body>
    <b-card-body>
      <b-row>

         <!-- Arama -->
        <b-col md="3">
          <b-form-group>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input v-model="queryFilter" :placeholder="$t('Ara...')" />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3"
          class="mb-md-0 mb-2">
          <v-select
            v-model="isLisansGecerliFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="lisansSifreBilgiDurumOptions"
            class="w-100"
            :reduce="val => val.value"
            :placeholder="$t('Durum')" />
        </b-col>

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>

// Options
import StaticOptions from '@/common/options/StaticOptions'

export default {
  components: {
    
  },
  data() {
    return {
      lisansSifreBilgiDurumOptions: StaticOptions.lisansSifreBilgiDurumOptions,
    }
  },
  computed: {
    queryFilter: {
      get() {
        return this.$store.getters['lisanssifrebilgiCustomer/getLisansSifreBilgiFilters'].query
      },
      set(value) {
        this.$store.commit('lisanssifrebilgiCustomer/setLisansSifreBilgiFilters', { key: 'query', value })
      },
    },

    isLisansGecerliFilter: {
      get() {
        return this.$store.getters['lisanssifrebilgiCustomer/getLisansSifreBilgiFilters'].isLisansGecerli
      },
      set(value) {
        this.$store.commit('lisanssifrebilgiCustomer/setLisansSifreBilgiFilters', { key: 'isLisansGecerli', value })
      },
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
